import "./aboutus.css";
import AboutUsImg from "../../images/GoogleLogo.png";
import RatingImg from "../../images/Rating.png";
import MedhaImg from "../../images/11.png";
import BharadwajImg from "../../images/12.png";
import RaiImg from "../../images/13.png";
import Grid from "@mui/material/Grid";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
function AboutUs() {
  return (
    <div className="about-us-root" data-aos="fade-right" data-aos-delay="200">
      <div className="About-Us">
        <Grid container spacing={1} className="about-grid">
          <Grid item xs={12} sm={5} className="about-img"  >
            <img className="aboutimg" src={AboutUsImg} />
            <img className="googleimg" src={RatingImg} />
          </Grid>
          <Grid item xs={12} sm={7}>
            <div  >
              <p className="aboutus-heading">Get To Know Us</p>
            </div>
            <div  >
              <p className="aboutus-para">
                We are experts in drafting top notch SOP, LORs, and Scholarship
                Essays
              </p>
            </div>
            <div className="actions-div">
              <div className="check-flex">
                <CheckCircleOutlineIcon
                  
                  
                />

                <p
                  className="action-para"
                  
                  
                >
                  We have documented around 9000,0000 words over the past 4.5
                  years and we have around 2500,0000 words of assignments every
                  year.
                </p>
              </div>
              <div className="action-flex">
                <CheckCircleOutlineIcon
                  
                  
                />

                <p
                  className="action-para"
                  
                  
                >
                  We have assisted students with more than $250,000 of
                  scholarships for some of the leading Universities.
                </p>
              </div>
              <div className="action-flex">
                <CheckCircleOutlineIcon
                  
                  
                />
                <span>
                  <p
                    className="action-para"
                    
                    
                  >
                    Our report states that our plagirism score is at 98.678%
                    unique while our service ratings have always been between
                    4.9 and 5.
                  </p>
                </span>
              </div>
              <div className="action-flex">
                <CheckCircleOutlineIcon
                  
                  
                />
                <span>
                  <p
                    className="action-para"
                    
                    
                  >
                    We are working as the background operations for more than 15
                    leading consulting companies in admission and immigration.
                  </p>
                </span>
              </div>
            </div>
          </Grid>
        </Grid>
      </div>
      <div>
        <Grid container className="rating" columns={{ xs: 4, sm: 8, md: 12 }}>
          <Grid
            item
            xs={3}
            sm={3}
            md={4}
            
            
          >
            <img className="ratingImg" src={MedhaImg} />
          </Grid>
          <Grid
            item
            xs={3}
            sm={3}
            md={4}
            
            
          >
            <img className="ratingImg2" src={BharadwajImg} />
          </Grid>
          <Grid
            item
            xs={3}
            sm={3}
            md={4}
            
            
          >
            <img className="ratingImg3" src={RaiImg} />
          </Grid>
        </Grid>
      </div>
    </div>
  );
}
export default AboutUs;
