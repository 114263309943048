import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import servieImg1 from "../../services SVG files/1.svg";
import servieImg2 from "../../services SVG files/2.svg";
import servieImg3 from "../../services SVG files/3.svg";
import servieImg4 from "../../services SVG files/4.svg";
import servieImg5 from "../../services SVG files/5.svg";
import servieImg6 from "../../services SVG files/6.svg";
import "./services.css";
// const Item = styled(Paper)(({ theme }) => ({
  // backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  // ...theme.typography.body2,
  // padding: theme.spacing(1),
  // textAlign: 'center',
  // color: theme.palette.text.secondary,
  // minHeight:'50px',
  // minWidth:'50px',
  // backgroundColor:'#e7f5fe'
// }));
function Services() {
  return (
    <div data-aos="fade-right" data-aos-delay="200" class="desire-root">
      <div className="admits-bar">
        <div className="admits-text">
          {" "}
          <p>SERVICES</p>
        </div>
      </div>
      <Grid container spacing={1}>
        <Grid item xs={12} sm={4}  > 
          <div class="title-image-div">
            <img className="title-image" src={servieImg1}></img>
          </div>
          <div class="title-div">
            <h2 class="titles">Statement Of Purpose</h2>
          </div>
          <p className="contents">
            Editing outstanding SoPs that assuages the admissions Council of
            finding a right candidate for their University.
          </p>
        </Grid>
        <Grid item xs={12} sm={4}  >
          <div class="title-image-div">
            <img className="title-image" src={servieImg2}></img>
          </div>
          <div class="title-div">
            <h2 class="titles">Letter Of Recommendation</h2>
          </div>
          <p className="contents">
            Helping you or your faculty write genuine recommendation letters
            that reflect your personality that would impress admissons panel.
          </p>
        </Grid>
        <Grid item xs={12} sm={4}  >
          <div class="title-image-div">
            <img className="title-image" src={servieImg3}></img>
          </div>
          <div class="title-div">
            <h2 class="titles">Short Answer Essays</h2>
          </div>
          <p className="contents">
            Get you through submitting on-point short answers that struck a
            chord with the examiners and grab their attention.
          </p>
        </Grid>
        <Grid item xs={12} sm={4}  >
          <div class="title-image-div">
            <img className="title-image" src={servieImg4}></img>
          </div>
          <div class="title-div">
            <h2 class="titles">Common App Essays</h2>
          </div>
          <p className="contents">
            Your undergrad essays shall get a personality that the universities
            would love to review your application.
          </p>
        </Grid>
        <Grid item xs={12} sm={4}  >
          <div class="title-image-div">
            <img className="title-image" src={servieImg5}></img>
          </div>
          <div class="title-div">
            <h2 class="titles">Cover letter</h2>
          </div>
          <p className="contents">
            We concoct cover letters that are deep in purpose and ooze your zeal
            to get through the recruiters eyes.
          </p>
        </Grid>
        <Grid item xs={12} sm={4}  >
          <div class="title-image-div">
            <img className="title-image" src={servieImg6}></img>
          </div>
          <div class="title-div">
            <h2 class="titles">Scholarship Esays</h2>
          </div>
          <p className="contents">
            Handholding support and extensive counselling in writing scholarship
            Essays that exponentially increase grant chances.
          </p>
        </Grid>
      </Grid>
    </div>
  );
}
export default Services;
