import "./App.css";
import Header from "./components/header/header";
import HomePage from "./components/homepage/homepage";
import TopAdmits from "./components/topadmits/topadmits";
import Service from "./components/services/services";
import Features from "./components/features/features";
import Whatsapp from "./components/whatsapp/whatsapp";
import Footer from "./components/footer/footer";
import AboutUs from "./components/aboutus/aboutus";

function App() {
  return (
    <div className="App container">
      <Header></Header>
      
      <div className="homepage">
        <HomePage></HomePage>
      </div>

      <div>
        <AboutUs />
      </div>

      <div>
        <TopAdmits></TopAdmits>
      </div>

      <div>
        <Service />
      </div>

      <div>
        <Features />
      </div>

      <Whatsapp />
      <Footer />
    </div>
  );
}
export default App;
