import Grid from "@mui/material/Grid";
import "./features.css";
import featureImage from "../../images/featureImage.png";
import featureImg1 from "../../images/featureImg1.svg";
import featureImg2 from "../../images/featureImg2.svg";
import featureImg3 from "../../images/featureImg3.svg";
import featureImg4 from "../../images/featureImg4.svg";
import featureImg5 from "../../images/featureImg5.svg";

function Features() {
  return (
    <div data-aos="fade-right" data-aos-delay="200">
      <div class="main-root">
        <div className="admits-bar">
          <div className="admits-text">
            {" "}
            <p>WHY US?</p>
          </div>
        </div>
        <Grid container>
          <Grid item xs={12} sm={4}  >
            <div className="title-div">
              <h2 class="title">Research</h2>
              <h2 class="feature-image-div">
                <img className="feature-image" src={featureImg1}></img>
              </h2>
            </div>

            <p class="content">
              A Statement of Purpose or a College Essay requires extensive
              research about self, the University, and the Course program before
              putting your pen on the paper. We lift off a huge burden on you in
              this regard by asking a few simple questions that our editors will
              infer to create a customized Essay
            </p>
          </Grid>
          <Grid item xs={12} sm={4}  >
            <div className="title-div">
              <h2 class="title">Winning Format</h2>
              <h2 class="feature-image-div">
                <img className="feature-image" src={featureImg2}></img>
              </h2>
            </div>
            <p class="content">
              We follow the most desirable format of the SoP or LoR that the top
              Universities have repeatedly accepted. It answers all the required
              questions of the essay while maintaining a coherent flow
            </p>
          </Grid>
          <Grid item xs={12} sm={4}  >
            <div className="title-div">
              <h2 class="title">Always fresh</h2>
              <h2 class="feature-image-div">
                <img className="feature-image" src={featureImg3}></img>
              </h2>
            </div>
            <p class="content">
              We constantly reinvent our vocabulary and sentences to keep our
              essays fresh and impactful. This trait earned us repeat customers
              who recommended our services to yet some more
            </p>
          </Grid>
          <Grid item xs={12} sm={4}  >
            <div className="title-div">
              <h2 class="title">Genuineness</h2>
              <h2 class="feature-image-div">
                <img className="feature-image" src={featureImg4}></img>
              </h2>
            </div>
            <p class="content">
              We do not cook up any lies but instead use your past achievements,
              interests, and hobbies to showcase your skills and throw light on
              your forte to impress the admissions panel, keeping the essay's
              truthfulness intact{" "}
            </p>
          </Grid>
          <Grid item xs={12} sm={4}  >
            <div className="title-div">
              <h2 class="title">Professional writers</h2>
              <h2 class="feature-image-div">
                <img className="feature-image" src={featureImg5}></img>
              </h2>
            </div>
            <p class="content">
              Our content writers are time tested with an experience of more
              than 1000 cases each, delivering flawless, grammatically perfect,
              and winning essays by focusing on your core intent of the
              application
            </p>
          </Grid>
          <Grid item xs={12} sm={4}  >
            <div className="feature-image-div">
              <img class="feature-image-main" src={featureImage}></img>
            </div>
          </Grid>
        </Grid>
      </div>
    </div>
  );
}
export default Features;
